@import '../../common/styles/variables';

.dev-datetimepicker {
    border: 1px solid $blue-datetime-border;

    .dev-datetimepicker-option:hover,
    .dev-datetimepicker-option-cur {
        background: $blue-content-active-background;
        color: $blue-content-active;
    }

    .dev-datetimepicker-option-cur:hover {
        background: $blue-content-active-background !important;
        color: $blue-content-active !important;
    }

    .dev-datetimepicker-nav {
        .dev-datetimepicker-nav-left,
        .dev-datetimepicker-nav-right {
            color: $blue-content-active-background;
        }
    }
}

.dev-datetimepicker-range {
    border: 1px solid $blue-datetime-border;
}

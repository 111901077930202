@import '../../common/styles/variables';

.dev-colorpicker {
    .dev-colorpicker-row-split {
        color: $blue-plain;

        hr {
            border-top: 1px solid $blue-division;
        }
    }
}
